import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'

export enum SubhomeKey {
  MAIN = '/',
  HALLOWEEN = 'HALLOWEEN',
  BELLEZA = 'BELLEZA',
  MUJER = 'MUJER',
  COCINA = 'COCINA',
  MASCOTAS = 'MASCOTAS',
  ACCESORIOS_DE_BELLEZA = 'ACCESORIOS_DE_BELLEZA',
  NINO = 'NIÑO',
  PAPELERIA = 'PAPELERÍA',
  NINA = 'NIÑA',
  HOMBRE = 'HOMBRE',
  HOGAR = 'HOGAR',
  PIÑATERIA = 'PIÑATERIA',
  JUGUETES = 'JUGUETES',
  JOYERIA_Y_ACCESORIOS = 'JOYERÍA_Y_ACCESORIOS',
  CALZADO = 'CALZADO',
  ASEO = 'ASEO',
  ACCESORIOS_ELECTRONICOS = 'ACCESORIOS_ELECTRÓNICOS',
  SUPLEMENTOS_DEPORTIVOS = 'SUPLEMENTOS_DEPORTIVOS',
  MATERNIDAD = 'MATERNIDAD_Y_BEBÉS',
  ACCESORIOS_DEPORTIVOS = 'ACCESORIOS_DEPORTIVOS',
  ADULTOS = 'ADULTOS',
}

export enum TabBarOptions {
  EXPLORE = 'Explora',
  RECOMMENDED = 'Recomendados',
  POPULAR = 'MasVendidos',
  OFFER = 'Ofertas',
  LATEST = 'Novedades',
}

export type SubhomeScreenContextType = {
  currentScreen: SubhomeKey
  changeScreen: (newScreen: SubhomeKey) => void
  categories: { [key: string]: CategoryPublic[] }
  currentTabBarOption: TabBarOptions
  updateTabBarOption: (newTabBarOption: TabBarOptions) => void
  currentPages: { [key: string]: number }
  updateCurrentPages: (key: string, value: number) => void
  isTabBarSticky: boolean
  updateIsTabBarSticky: (isSticky: boolean) => void
}
